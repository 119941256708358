<script lang="ts">
import toastr from 'toastr/toastr';
import { defineComponent, PropType } from 'vue';

type MessageType = 'notice' | 'alert';
type Props = {
  messageType: MessageType;
  message: string;
}
export default defineComponent({
  props: {
    messageType: {
      type: String as PropType<MessageType>,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  setup(props: Props) {
    toastr.options.closeButton = true;
    toastr.options.timeOut = 50000;
    toastr.options.positionClass = 'toast-under-header-top-right';

    return () => {
      const { messageType, message } = props;

      switch (messageType) {
        case 'notice':
          toastr.success(message);
          break;
        case 'alert':
          toastr.error(message);
          break;
        default:
          break;
      }
    };
  },
});
</script>
